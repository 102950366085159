<template>
  <div>
    <table cellpadding="10" cellspacing="0" width="95%" class="hover-table invoices-table" align="center">
      <thead>
        <tr>
          <td valign="middle" v-if="pending &&
            (userRoleInvTable === 'AFF' || userRoleInvTable === 'AFA')
            "></td>
          <td valign="middle">
            <strong>{{ pending ? $t("invoicesFilterProduct" ) : $t("invoiceSeriesHeader" ) }}</strong>&nbsp;
          </td>
          <td valign="middle">
            <strong>{{ $t("invoiceNumberHeader") }}</strong>&nbsp;
          </td>
          <td class="priority-1" valign="middle" v-if="userRoleInvTable === 'AFF' || userRoleInvTable === 'AFA'">
            <strong>{{ $t("ordersTableHeaderType") }}</strong>
            &nbsp;
          </td>
          <td class="priority-1" valign="middle">
            <strong>{{ $t("invoiceDateHeader") }}</strong>&nbsp;
            <img v-if="pending === false" src="@/assets/images/arrow-down-fill.svg" @click="sortBy('date')" :style="sortByField === 'date' && reverse && 'transform: rotate(180deg)'
              " />
          </td>
          <td class="priority-2" valign="middle">
            <strong>{{ $t("invoiceStatusHeader") }}</strong>&nbsp;
            <img v-if="pending === false" src="@/assets/images/arrow-down-fill.svg" @click="sortBy('status')" :style="sortByField === 'status' &&
              reverse &&
              'transform: rotate(180deg)'
              " />
          </td>
          <td class="priority-3" valign="middle">
            <strong>{{ $t("invoiceAmountHeader") }}</strong>&nbsp;
            <img v-if="pending === false" src="@/assets/images/arrow-down-fill.svg" @click="sortBy('amount')" :style="sortByField === 'amount' &&
              reverse &&
              'transform: rotate(180deg)'
              " />
          </td>
          <td class="priority-4" valign="middle">
            <strong>{{ $t("invoiceDownloadPDFHeader") }}</strong>&nbsp;
          </td>
          <td v-if="userRoleInvTable === 'AFF' || userRoleInvTable === 'AFA' || userRoleInvTable === 'MTB' " class="priority-4" valign="middle">
            <strong>{{ $t("invoiceDownloadEXLHeader") }}</strong>&nbsp;
          </td>
          <!-- <td valign="middle"></td> -->
          <td valign="middle" v-if="pending &&
            (userRoleInvTable === 'AFF' || userRoleInvTable === 'AFA')
            "></td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="this.companyInvoices === null || this.companyInvoices === undefined
          ">
          <td colspan="12">
            <div class="loading">
              <img src="@/assets/images/loading.gif" />
            </div>
          </td>
        </tr>
        <template v-for="(invoice, index) in companyInvoices">
          <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
            <td valign="middle" v-if="pending &&
              (userRoleInvTable === 'AFF' || userRoleInvTable === 'AFA')
              ">
              <!-- invoice.status !== 'V' && -->
              <input v-if="
                invoice.amount >= 0 &&
                invoice.product.card !== 0
                " 
                type="checkbox" v-model="selectedInvoiceForPay" :value="invoice" @change="
    selectInvoiceActionClick(invoice, $event.target.checked)
    " />
            </td>
            <td valign="middle">
              <a class="detailsOrderMobile priority-show-5 display-inline-block" @click="selectInvoiceMobile(index)">
                <font-awesome-icon icon="minus-circle" v-if="detailsMobile && invoice === invoiceSelectedMobile" />
                <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
              </a>
              <strong>{{ pending ? invoice.product.name : invoice.type }}</strong>
            </td>
            <td valign="middle">{{ invoice.invoice }}</td>
            <td valign="middle" class="priority-1" v-if="userRoleInvTable === 'AFF' || userRoleInvTable === 'AFA'">
              <img :src="require('@/assets/images/' +
                (invoice.product.card === 0 ? 'PAPER.svg' : 'NEW.svg'))
                " />
            </td>
            <td valign="middle" class="priority-1">
              {{ displayDate(invoice.date) }}
            </td>
            <td valign="middle" class="priority-2">
              <span class="status-background" :class="selectColorStatus(invoice.status)"  v-if="!isInvoiceMutiben(invoice.type)">{{ $t("invoiceStatus_" +
                invoice.status) }}</span>
            </td>
            <td valign="middle" class="priority-3">
              {{ invoice.amount }} {{ $t("currency") }}
            </td>
            <td valign="middle" class="priority-4">
              <a class="invoice-icon cursor-pointer" title="Descarca pdf" @click="downloadInvoiceFile(invoice.id)" >&nbsp;</a>
              <!-- <a v-if="isInvoiceMutiben(invoice.type)" class="invoice-excel-icon cursor-pointer" title="Descarca anexa" @click="downloadInvoiceExcelFile(invoice.id)">&nbsp;</a> -->
            </td>
            <td v-if="userRoleInvTable === 'AFA' || userRoleInvTable === 'AFF' || isInvoiceMutiben(invoice.type)" valign="middle" class="priority-4">
              <a v-if="invoice.product.card > 0" class="invoice-excel-icon cursor-pointer"
                @click="downloadInvoiceExcelFile(invoice)">&nbsp;</a>
            </td>
            <!-- <td valign="middle">
              <a class="action-button" v-if="isInvoiceOug(invoice)" @click="
                showModalPopup('modal_popup_oug' + pending, true, index)
                ">
                <span>{{ $t("invoiceAffiliateOugPopup") }}</span>
              </a>
            </td> -->
            <td valign="middle">
              <!-- <a class="see-more mr-2">
                <font-awesome-icon icon="print" size="2x" />
              </a> -->
              <a class="see-more" @click="
                showModalPopup('modal_popup_invoices' + pending, true, index)
                ">
                <font-awesome-icon far icon="envelope" size="2x" />
              </a>
            </td>

            <td valign="middle" v-if="selectedInvoiceForPay.length < 2 &&
              pending &&
              (userRoleInvTable === 'AFF' || userRoleInvTable === 'AFA')
              ">
              <a v-if="invoice.status !== 'V' &&
                invoice.amount >= 0 &&
                invoice.product.card !== 0
                " class="action-button" @click="selectInvoiceActionClick(invoice, 'pay')">
                <span>{{ $t("payInvoiceButtonLabel") }}</span>
              </a>
            </td>
          </tr>
          <tr :key="index + companyInvoices.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
            v-show="detailsMobile && invoice === invoiceSelectedMobile">
            <td colspan="12">
              <p class="priority-show-1" v-if="userRoleInvTable === 'AFF' || userRoleInvTable === 'AFA'">
                <strong>{{ $t("ordersTableHeaderType") }} : &nbsp;&nbsp;</strong>
                <span><img :src="require('@/assets/images/' +
                  (invoice.product.card === 0 ? 'PAPER.svg' : 'NEW.svg'))
                  " /></span>
              </p>
              <p class="priority-show-1">
                <strong>{{ $t("invoiceDateHeader") }} : &nbsp;&nbsp;</strong>
                <span>{{ invoice.date }}</span>
              </p>
              <p class="priority-show-2" v-if="!isInvoiceMutiben(invoice.type)">
                <strong>{{ $t("invoiceStatusHeader") }} : &nbsp;&nbsp;</strong>
                <span class="status-background" :class="selectColorStatus(invoice.status)">{{ $t("invoiceStatus_" +
                  invoice.status) }}</span>
              </p>
              <p class="priority-show-3">
                <strong>{{ $t("invoiceAmountHeader") }} : &nbsp;&nbsp;</strong>
                <span>{{ invoice.amount }} {{ $t("currency") }}</span>
              </p>
              <p class="priority-show-4">
                <strong>{{ $t("invoiceDownloadPDFHeader") }} : &nbsp;&nbsp;</strong>
                <span>
                  <a class="invoice-icon cursor-pointer" @click="downloadInvoiceFile(invoice.id)">&nbsp;</a>
                </span>
              </p>
              <p v-if="(userRoleInvTable === 'AFF' || userRoleInvTable === 'AFA') &&
                invoice.product.card > 0
                " class="priority-show-4">
                <strong>{{ $t("invoiceDownloadEXLHeader") }} : &nbsp;&nbsp;</strong>
                <span>
                  <a class="invoice-icon cursor-pointer" @click="downloadInvoiceExcelFile(invoice)">&nbsp;</a>
                </span>
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <div :id="'modal_popup_invoices' + pending" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_invoices' + pending, false, -1)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="sentInvoicesError === null">
          <p v-if="!successSentInvoices">
            <input type="email" class="invoices-input formedituser" v-model="emailSender"
              :placeholder="[[$t('emailField')]]" />
          </p>
          <a v-if="!successSentInvoices" class="button-orange" @click="sendInvoiceFile(invoiceSelectedMobile.id)">{{
            $t("textButtonForm") }}</a>
          <p v-else>{{ $t("msgEmailSuccess") }}</p>
        </div>
        <div v-else>
          <p v-if="this.sentInvoicesError" v-html="$t('errorActionBeneficiary_500')"></p>
        </div>
      </div>
    </div>

    <div :id="'modal_popup_oug' + pending" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_oug' + pending, false, -1)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <table cellpadding="20" cellspacing="0" width="95%" class="hover-table mobile-table" align="center">
          <tr>
            <td colspan="3">
              <strong>{{ $t("invoiceAffiliateOugTitle") }}</strong>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <!-- {{ $t("orderFileUploadText") }}
                  <br />
                  <br /> -->

              <b-form-file class="text-left" v-model="fileReceived" ref="file" accept=".xls, .xlsx"
                :placeholder="[[$t('uploadFileLabel')]]" @input="uploadFile()"></b-form-file>
              <br />
              <br />
              <div class="password">
                <div class="w-100 text-left">
                  <a class="status-background background-active" @click="downloadModel">{{
                    $t("invoiceAffiliatDownloadModel") }}</a>
                </div>

                <div class="w-100 text-right">
                  <a @click="fileReceived = null" class="status-background background-will-expire">Reset</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-check">
                <input type="checkbox" id="terms" v-model="terms" class="form-check-input" required />
                <label for="terms">
                  {{ $t("invoiceAffiliatCheckboxLabel") }}

                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">

              <div>
                <a @click="uploadFileToServer()" class="status-background background-will-expire">Incarca</a>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div :id="'modal_popup_info' + pending" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info' + pending, false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.downloadInvoicesError">
          <p v-html="$t('errorMessage_' + this.downloadInvoicesError.code)"></p>
        </div>
        <!-- <div v-if="this.ougError">
          <p v-html="$t('ougErrorMessage_' + this.ougError.error_code)"></p>
        </div>
        <div v-if="this.ougSuccess">
          <p v-html="$t('ougSuccessMessage')"></p>
        </div> -->
      </div>
    </div>

    <div :id="'modal_popup_loading' + pending" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>
<script>
import httpServiceCustomer from "@/http/http-customer.js";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import { BFormFile } from "bootstrap-vue";

import { saveAs } from "file-saver";
import Cookies from "js-cookie";
import utils from "@/plugins/utils.js";
import moment from "moment";

export default {
  name: "InvoiceTableDesktop",
  props: {
    companyInvoices: Array,
    company: Object,
    userRoleInvTable: String,
    msg: String,
    pending: Boolean
  },
  components: {
    "b-form-file": BFormFile,
  },
  data() {
    return {
      detailsMobile: false,
      invoiceSelectedMobile: null,
      sortByField: "date",
      reverse: false,
      emailSender: null,
      successSentInvoices: false,
      sentInvoicesError: null,
      downloadInvoicesError: null,
      selectedInvoiceForPay: [],
      componentKey: 0,
      fileReceived: null,
      fileContent: null,
      ougError: null,
      ougSuccess: false,
      maxdays: 30,
      terms: false
    };
  },
  methods: {
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
    },
    downloadInvoiceFile(invoiceNumber, isExcel) {
      this.showModalPopup("modal_popup_loading" + this.pending, true, -1);
      let link =
        this.company.company_id + "/customer_order_documents/" + invoiceNumber;
      httpServiceCustomer.customerService
        .getOrderDocumentFile(link, Cookies.get("token"), false)
        .then(function (response) {
          return response;
        })

        .then(response => {
          if (response.status == 200) {
            this.showModalPopup(
              "modal_popup_loading" + this.pending,
              false,
              -1
            );
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.content)],
              {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
              }
            );
            saveAs(blob, response.data.invoice + (isExcel ? ".xls" : ".pdf"));
            this.downloadInvoicesError = null;
          } else if (response.status == 400 || response.status == 403) {
            this.downloadInvoicesError = { code: response.status };
            this.deliveryAddresses = null;
            this.showModalPopup(
              "modal_popup_loading" + this.pending,
              false,
              -1
            );
            this.showModalPopup("modal_popup_info" + this.pending, true, -1);
          } else if (response.status == 404) {
            this.downloadInvoicesError = { code: response.status };
            this.showModalPopup(
              "modal_popup_loading" + this.pending,
              false,
              -1
            );
            this.showModalPopup("modal_popup_info" + this.pending, true, -1);
          } else {
            this.downloadInvoicesError = { code: "503" };
            this.showModalPopup(
              "modal_popup_loading" + this.pending,
              false,
              -1
            );
            this.showModalPopup("modal_popup_info" + this.pending, true, -1);
          }
        })
        .catch(error => {
          this.downloadInvoicesError = { code: "503" };
          this.deliveryAddresses = null;
          this.showModalPopup("modal_popup_loading" + this.pending, false, -1);
          this.showModalPopup("modal_popup_info" + this.pending, true, -1);
        });
    },
    downloadInvoiceExcelFile(invoice) {
      let document = "212" + invoice.id.substring(3);
      if (this.isInvoiceMutiben(invoice.type)){
        document = "131" + invoice.id.substring(3);
      }
      this.downloadInvoiceFile(document, true);
    },
    sendInvoiceFile(invoiceNumber) {
      let formData = {
        code: this.company.company_id,
        invoice: invoiceNumber,
        email_sender: this.emailSender
      };
      httpServiceCustomer.customerService
        .sentEmailInvoiceFile(Cookies.get("token"), formData)
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.sentInvoicesError = {
            code: error.response.status
          };
        })
        .then(response => {
          if (response.status == 200) {
            this.successSentInvoices = true;
          } else if (
            response.status == 400 ||
            response.status == 403 ||
            response.status == 404
          ) {
            this.sentInvoicesError = response.response;
          }
        });
    },
    selectInvoiceMobile(index) {
      this.invoiceSelectedMobile = this.companyInvoices[index];
      this.detailsMobile = !this.detailsMobile;
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverse = false;
      }
      this.sortByField = fieldName;
      this.reverse = !this.reverse;
      this.$emit("change", fieldName, this.reverse ? "asc" : "desc");
    },
    downloadModel() {
      this.$emit("model");
    },
    selectColorStatus(status) {
      switch (status) {
        case "P":
        case "L":
        case "R":
          return "background-active";
        case "U":
        case "Not paid":
          return "background-will-expire";
        case "O":
          return "background-suspended";
        default:
          return "background-will-expire";
      }
    },
    isInvoiceMutiben(type){
      return type === "MB";
    },
    showModalPopup(elementId, show, index) {
      if (index >= 0) {
        this.invoiceSelectedMobile = this.companyInvoices[index];
      }
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    selectInvoiceAction(invoice, action) {
      if (
        this.pending &&
        (this.userRoleInvTable === "AFF" || this.userRoleInvTable === "AFA")
      ) {
        if (action === "pay") {
          this.selectedInvoiceForPay = [];
          for (let index in this.selectedInvoiceForPay) {
            if (invoice.id === this.selectedInvoiceForPay[index].id) {
              return;
            }
          }
          this.selectedInvoiceForPay.push(invoice);
        } else if (action === true) {
          for (let index in this.selectedInvoiceForPay) {
            if (invoice.id === this.selectedInvoiceForPay[index].id) {
              return;
            }
          }
          this.selectedInvoiceForPay.push(invoice);
        } else if (action === false) {
          for (let index in this.selectedInvoiceForPay) {
            if (invoice.id === this.selectedInvoiceForPay[index].id) {
              this.selectedInvoiceForPay.splice(index, 1);
            }
          }
        }
      }
    },
    uploadFile() {
      // this.newOrder.content =
      // utils.uploadFile(
      //   this.fileReceived
      //   // this.newOrder.content
      // );
      // /*\
      var reader = new FileReader();
      reader.onload = (e) => {
        if (!e) {
          this.fileContent = btoa(reader.content);
        } else {
          this.fileContent = btoa(e.target.result);
        }
      };
      //For Browsers other than IE.
      if (reader.readAsBinaryString) {
        reader.readAsBinaryString(this.fileReceived);
      } else {
        alert("This browser does not support HTML5.");
      }

      if (!this.fileReceived) {
        return;
      }
      // */
    },
    uploadFileToServer() {
      if (!this.fileContent) {
        this.ougError = {
          description: "No file",
          error_code: "NOFILE",
        };
        this.showModalPopup('modal_popup_info' + this.pending, true);

        return;
      }
      if (
        !this.terms ||
        this.terms === false
      ) {
        this.ougError = {
          description: "Check",
          error_code: "CHECK",
        };
        this.showModalPopup('modal_popup_info' + this.pending, true);

        return;
      }

      this.ougError = null;
      var invoiceData = {
        product: 0,
        content: this.fileContent,
        code: this.company.company_id,
        invoice: this.invoiceSelectedMobile.invoice
      };
      this.ougSuccess = false;
      this.showModalPopup("modal_popup_loading" + this.pending, true);
      httpServiceAffiliate.affiliateService
        .uploadOugFile(
          this.company.company_id,
          invoiceData,
          Cookies.get("token"),
        )
        .then(function (response) {
          return response;
        })
        .then((response) => {
          this.ougError = null;
          if (response.status === 200) {
            this.ougSuccess = true;
          } else
            if (response.status === 400) {
              this.ougError = response.data;
            }

          this.showModalPopup("modal_popup_loading" + this.pending, false);
          this.showModalPopup('modal_popup_info' + this.pending, true);
        })
        .catch((error) => {
          this.ougError =
            error.response.data;
          this.showModalPopup("modal_popup_loading" + this.pending, false);
          this.showModalPopup('modal_popup_info' + this.pending, true);

        });
    },
    selectInvoiceActionClick(invoice, action) {
      this.$emit("click", invoice, action);
      this.selectInvoiceAction(invoice, action);
    },
    resetSelectedInvoices() {
      this.selectedInvoiceForPay = [];
    },
    selectAllInvoicesForPay() {
      this.selectedInvoiceForPay.push(...this.companyInvoices);
    },
    // isInvoiceOug(invoice) {
    //   return (this.userRoleInvTable === 'AFF' || this.userRoleInvTable === 'AFA')
    //     && moment(invoice.date) > moment('2023-07-29') &&  invoice.amount > 0 &&
    //     (invoice.product.card == 13 || invoice.product.card == 11
    //       || invoice.product.paper == 6 || invoice.product.card == 15);
    // },
  },
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
