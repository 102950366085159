<template>
  <div class="container-bg-white mt-3">
    <div class="row p-3">
      <div class="col-12">
        <h3 class="my-0 py-1 pl-0">{{$t("flexben.general.details")}}</h3>
      </div>
      <div v-if="formData.deleted_at && formData.status === 'deleted'" class="col-12 col-md-6 col-xl-4 form-group mb-0">
        <label class="m-0 col-form-label" for="deleted_at">
          {{ $t('flexben.import.table_headings.thresholds.' + 'deleted_at') }}</label>
        <input class="form-control m-0 w-100"
               id="deleted_at" disabled
               v-model.number="formData.deleted_at">
      </div>
      <div class="col-12" v-if="checkNotEmptyObj(errorBag)">
        <FormErrorList :errorBag="errorBag"></FormErrorList>
      </div>
      <div class="col-12 mt-2"></div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="first_name" class="form-fields-required col-form-label">
          {{ $t('flexben.import.table_headings.first_name') }}</label>
        <input type="text" maxlength="255" id="first_name" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('first_name') || checkInErrorBag('name_mix')}"
               v-model="formData.first_name">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="last_name" class="form-fields-required col-form-label">
          {{ $t('flexben.import.table_headings.last_name') }}</label>
        <input type="text" maxlength="255" id="last_name" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('last_name') || checkInErrorBag('name_mix')}"
               v-model="formData.last_name">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="invitation_email" class="form-fields-required col-form-label">
          {{ $t('flexben.import.table_headings.invitation_email') }}</label>
        <input type="text" maxlength="255" id="invitation_email" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('invitation_email')}"
               v-model="formData.invitation_email">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="phone_number" class="form-fields-required col-form-label">
          {{ $t('flexben.import.table_headings.phone_number') }}</label>
        <input type="text" maxlength="255" id="phone_number" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('phone_number')}"
               v-model="formData.phone_number">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="invitation_channel" class="form-fields-required col-form-label">
          {{ $t('flexben.import.table_headings.invitation_channel') }}</label>
        <select name="invitation_channel" id="invitation_channel"
                class="form-control w-100 m-0"
                :class="{'border-danger':checkInErrorBag('invitation_channel')}"
                v-model="formData.invitation_channel">
          <option v-for="(item, index) of form_options.invitation_channels"
                  :key="index"
                  :value="item">{{ item }}</option>
        </select>
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="cnp" class="form-fields-required col-form-label">{{ $t('flexben.import.table_headings.cnp') }}</label>
        <input type="text" maxlength="255" id="cnp" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('cnp')}"
               :disabled="edit"
               v-model="formData.cnp">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="benefit_group" class="form-fields-required col-form-label">
          {{ $t('flexben.import.table_headings.benefit_group') }}</label>
        <select id="benefit_group" class="form-control w-100 m-0"
                :class="{'border-danger':checkInErrorBag('company_benefit_group_id')}"
                v-model="formData.company_benefit_group_id">
          <option :value="group.id" v-for="group in form_options.benefit_groups" :key="group.id">{{group.name}}
          </option>
        </select>
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="employee_number" class="col-form-label">
          {{ $t('flexben.import.table_headings.employee_number') }}</label>
        <input type="text" maxlength="255" id="employee_number" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('employee_number')}"
               v-model="formData.employee_number">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="department" class="col-form-label">{{ $t('flexben.import.table_headings.department') }}</label>
        <input type="text" maxlength="255" id="department" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('department')}"
               v-model="formData.department">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="job_title" class="">{{ $t('flexben.import.table_headings.job_title') }}</label>
        <input type="text" maxlength="255" id="job_title" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('job_title')}"
               v-model="formData.job_title">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="cost_center" class="col-form-label">{{ $t('flexben.import.table_headings.cost_center') }}</label>
        <input type="text" maxlength="255" id="cost_center" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('cost_center')}"
               v-model="formData.cost_center">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="working_point" class="col-form-label">{{ $t('flexben.import.table_headings.working_point')
          }}</label>
        <input type="text" maxlength="255" id="working_point" class="form-control w-100 m-0"
               :class="{'border-danger':checkInErrorBag('working_point')}"
               v-model="formData.working_point">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="underage_children_nr" class="col-form-label">{{
          $t('flexben.import.table_headings.underage_children_nr')
          }}</label>
        <input type="number" id="underage_children_nr" min="0"
               class="form-control w-100"
               :class="{'border-danger':checkInErrorBag('underage_children_nr')}"
               v-model="formData.underage_children_nr">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="up_ro_address_id" class="form-fields-required col-form-label">{{
          $t('flexben.import.table_headings.up_ro_address_id')
          }}</label>
        <select name="up_ro_address_id" id="up_ro_address_id" v-model="formData.up_ro_address_id" class="form-control">
          <option :value="item.up_ro_address_id" v-for="item in form_options.delivery_addresses" :key="item.up_ro_address_id">
            {{item.up_ro_address_id}} - {{item.address}}
          </option>
        </select>
      </div>


      <div class="d-flex justify-content-start col-12 col-md-6 col-xl-4 form-group mb-0">
        <div><label class="m-0 col-form-label" for="up_dejun_enable_auto_topup">
          {{ $t('flexben.import.table_headings.up_dejun_enable_auto_topup') }}</label>
          <div class="d-flex mt-1">
            <span class="mr-3">{{ $t('flexben.general.no') }}</span>
            <b-form-checkbox id="top_up_fixed_values" class="cursor-pointer"
                             switch
                             v-model="formData.up_dejun_enable_auto_topup">
            </b-form-checkbox>
            <span class="ml-3">{{ $t('flexben.general.yes') }}</span>
          </div>
        </div>
        <div class="m-0 col-form-label update-info">
          <span>{{$t('flexben.beneficiaries.updated_at')}} </span>
            <span>{{ formattedDate }}</span>
            <span>{{ formattedTime }}</span>
        </div>
      </div>

      <div v-if="form_options.up_dejun_enabled"
           class="col-12 col-md-6 col-xl-4 form-group mb-0">
        <label for="up_dejun_ticket_value"
               class="m-0 col-form-label">
          <template v-if="!form_options.top_up_set_ticket_nr.enable">
            {{ $t('flexben.import.table_headings.up_dejun_ticket_value') }}
          </template>
          <template v-if="form_options.top_up_set_ticket_nr.enable">
            {{ $t('flexben.import.table_headings.up_dejun_ticket_count') }}
          </template>
        </label>

        <select v-if="form_options.top_up_fixed_values.enable"
                id="up_dejun_ticket_value"
                :disabled="!formData.up_dejun_enable_auto_topup"
                v-model="formData.up_dejun_ticket_value"
                :class="{'border-danger':checkInErrorBag('up_dejun_ticket_value')}"
                class="form-control">
          <option v-for="(value, index) of form_options.top_up_fixed_values.values"
                  :value="value"
                  :key="index">{{ value }}
          </option>
        </select>

        <input
          v-if="(!form_options.top_up_fixed_values.enable && !form_options.top_up_set_ticket_nr.enable)"
          type="number" step="0.01" class="form-control m-0 w-100 background-white" id="up_dejun_ticket_value"
          :class="{'border-danger':checkInErrorBag('up_dejun_ticket_value')}"
          :disabled="!formData.up_dejun_enable_auto_topup"
          v-model="formData.up_dejun_ticket_value">

        <select v-if="form_options.top_up_set_ticket_nr.enable"
                v-model="formData.up_dejun_ticket_count"
                :disabled="!formData.up_dejun_enable_auto_topup"
                :class="{'border-danger':checkInErrorBag('up_dejun_ticket_count')}"
                class="form-control" id="up_dejun_ticket_value">
          <option :value="null"
                  :selected="formData.up_dejun_ticket_count">
          </option>
          <option v-for="item in generateRange(1, 25)" :value="item" :key="item">{{ item }}</option>
        </select>
      </div>


      <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
        <label class="m-0 col-form-label" for="income_tax_percent">
          {{ $t('flexben.import.table_headings.income_tax_percent') }}</label>
        <span v-if="has_default_values.income_tax_percent" id="income_tax_percent_info" class="ml-2 d-inline-block flexben-tooltip-icon mr-2">i</span>
        <b-tooltip v-if="has_default_values.income_tax_percent" :target="'income_tax_percent_info'" variant="flexben" placement="topright">
          <div class="text-left">
            {{ $t('flexben.beneficiaries.tooltip.default_value') }}
          </div>
        </b-tooltip>
        <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
               id="income_tax_percent"
               :class="{'border-danger':checkInErrorBag('income_tax_percent')}"
               v-model.number="formData.income_tax_percent">
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
        <label class="m-0 col-form-label" for="cass_percent">{{ $t('flexben.import.table_headings.cass_percent') }}</label>
        <span v-if="has_default_values.cass_percent" id="cass_percent_info" class="ml-2 d-inline-block flexben-tooltip-icon mr-2">i</span>
        <b-tooltip v-if="has_default_values.cass_percent" :target="'cass_percent_info'" variant="flexben" placement="topright">
          <div class="text-left">
            {{ $t('flexben.beneficiaries.tooltip.default_value') }}
          </div>
        </b-tooltip>
        <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
               id="cass_percent"
               :class="{'border-danger':checkInErrorBag('cass_percent')}"
               v-model.number="formData.cass_percent">
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
        <label class="m-0 col-form-label" for="cas_percent">{{ $t('flexben.import.table_headings.cas_percent') }}</label>
        <span v-if="has_default_values.cas_percent" id="cas_percent_info" class="ml-2 d-inline-block flexben-tooltip-icon mr-2">i</span>
        <b-tooltip v-if="has_default_values.cas_percent" :target="'cas_percent_info'" variant="flexben" placement="topright">
          <div class="text-left">
            {{ $t('flexben.beneficiaries.tooltip.default_value') }}
          </div>
        </b-tooltip>
        <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
               id="cas_percent"
               :class="{'border-danger':checkInErrorBag('cas_percent')}"
               v-model.number="formData.cas_percent">
      </div>
      <div class="col-md-6 col-xl-4 form-group mb-0">
        <label for="fixed_benefit_group" class="col-form-label">{{
          $t('flexben.import.table_headings.fixed_benefit_group')
          }}</label>
        <select id="fixed_benefit_group" class="form-control w-100 m-0"
                :class="{'border-danger':checkInErrorBag('fixed_benefit_group')}"
                v-model="formData.fixed_benefit_group_id">
          <option :value="null">-</option>
          <option :value="group.id" v-for="group in form_options.fixed_benefit_groups" :key="group.id">{{group.name}}
          </option>
        </select>
      </div>
      <hr>
      <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
        <label class="m-0 col-form-label" for="cas_percent">
          {{ $t('flexben.import.table_headings.ppp3_deduction_limit') }}</label>
        <span v-if="has_default_values.ppp3_deduction_limit" id="ppp3_deduction_limit_info" class="ml-2 d-inline-block flexben-tooltip-icon mr-2">i</span>
        <b-tooltip v-if="has_default_values.ppp3_deduction_limit" :target="'ppp3_deduction_limit_info'" variant="flexben" placement="topright">
          <div class="text-left">
            {{ $t('flexben.beneficiaries.tooltip.default_value') }}
          </div>
        </b-tooltip>
        <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
               id="cas_percent"
               :class="{'border-danger':checkInErrorBag('ppp3_deduction_limit')}"
               v-model.number="formData.ppp3_deduction_limit">
      </div>
      <hr>
      <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
        <label class="m-0 col-form-label" for="cas_percent">{{ $t('flexben.import.table_headings.medical_insurance_deduction_limit') }}</label>
        <span v-if="has_default_values.medical_insurance_deduction_limit" id="medical_insurance_deduction_limit_info" class="ml-2 d-inline-block flexben-tooltip-icon mr-2">i</span>

        <b-tooltip v-if="has_default_values.medical_insurance_deduction_limit" :target="'medical_insurance_deduction_limit_info'" variant="flexben" placement="topright">
          <div class="text-left">
            {{ $t('flexben.beneficiaries.tooltip.default_value') }}
          </div>
        </b-tooltip>
        <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
               id="cas_percent"
               :class="{'border-danger':checkInErrorBag('medical_insurance_deduction_limit')}"
               v-model.number="formData.medical_insurance_deduction_limit">
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
        <label class="m-0 col-form-label" for="gym_membership">

          {{ $t('flexben.import.table_headings.thresholds.' + 'gym_membership') }}</label>
        <span v-if="has_default_values.gym_membership_threshold" id="cas_percent_info" class="d-inline-block flexben-tooltip-icon ml-2 mr-2">i</span>
        <b-tooltip v-if="has_default_values.gym_membership_threshold" :target="'cas_percent_info'" variant="flexben" placement="topright">
          <div class="text-left">
            {{ $t('flexben.beneficiaries.tooltip.default_value') }}
          </div>
        </b-tooltip>
        <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
               id="gym_membership"
               :class="{'border-danger':checkInErrorBag('gym_membership_threshold_limit')}"
               v-model.number="formData.thresholds.gym_membership.value">
      </div>
    </div>
  </div>
</template>
<script>
import FormErrorList from "@/components/fragments/FormErrorList";
import {BFormCheckbox, BTooltip} from "bootstrap-vue";

export default {
  name: "BeneficiaryDetails",
  components: {
    FormErrorList,
    "b-form-checkbox": BFormCheckbox,
    'b-tooltip': BTooltip,
  },
  props: {
    form_options: {
      type: Object,
      default: function () {
        return {
          benefit_groups: [],
          fixed_benefit_groups: [],
          delivery_addresses: [],
          invitation_channels: []
        };
      },
      required: true,
    },
    benefit_groups: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    fixed_benefit_groups: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    delivery_addresses: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },

    formData: {
      type: Object,
      default: function () {
        return {}
      },
      required: true,
    },
    errorBag: {
      default: function () {
        return {}
      },
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
      required: true,
    },
    has_default_values: {
      required: true,
      type: Object,
    }
  },
  methods: {
    checkInErrorBag(field) {
      if (!this.checkNotEmptyObj(this.errorBag)) {
        return false;
      }

      let objKeys = Object.keys(this.errorBag);
      return objKeys.includes(field);
    }
  },
  computed: {
    formattedDate() {
      if (this.formData && this.formData.lastAutoTopupEditTME && this.formData.lastAutoTopupEditTME.formatted_created_at) {
        return this.formData.lastAutoTopupEditTME.formatted_created_at.date;
      }
      return '-';
    },
    formattedTime() {
      if (this.formData && this.formData.lastAutoTopupEditTME && this.formData.lastAutoTopupEditTME.formatted_created_at) {
        return this.formData.lastAutoTopupEditTME.formatted_created_at.time;
      }
      return '-';
    },
  }
}
</script>
