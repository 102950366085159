export default {
  title: "Beneficiari",
  count: "Beneficiari activi",
  budget_title: "Buget flexibil",
  telework_budget_title: "Bugete telemunca",
  ppp3_budget_title: "Buget PPP3",
  ppp3_title: "PPP3",
  all_history_title: "Istoric autoalimentari",
  tme_auto_top_up_history: "Card de masa",
  ppp3_auto_top_up_history: "Pensie privata",
  insurance_auto_top_up_history:"Asigurari medicale",
  history_title: "Istoric",
  time_sheet: "Pontaj",
  updated_at: "Modificat la",
  timesheets: "Pontaje",
  auto_top_ups: "Alimentari automate",
  requests: "Solicitari",
  days_worked: "Zile lucrate",
  last_top_up_date: "Data limita alimentare",
  fields: {
    filter_title: "Beneficiarii de filtrare",
    search: "Cauta",
    beneficiary: "Beneficiari",
    client_company: "Client",
    invitation_email: "Email pentru invitatie",
    telephone: "Telefon",
    cnp: "CNP",
    employee_id: "ID angajat",
    group: "Grup",
    status: "Status",
    client: "Client",
    department: "Departament",
    job_title: "Functie",
    cost_center: "Centru de cost",
    working_point: "Punct lucru",
    up_ro_address_id: 'Cod adresa livrare carduri',
    address: 'Adresa',
    previous_budget: 'Buget precedent',
    ppp3_budget: 'Buget PPP3',
    ppp3_expiration_date: 'Data expirare PPP3',
    new_budget: 'Buget actual',
    difference: 'Diferenta',
    updated_budget_type: 'Motiv modificare',
    contribution_type: 'Tip contributie',
    contribution_value: 'Valoare contributie',
    personal_contribution_value: 'Valoare Contributie Personala',
    company_contribution_value: 'Valoare Contributie Companie',
    contract_number: 'Numar polita',
    benefit: 'Beneficiu',
    action: 'Actiune',
    number_of_tickets:'Numar de tichete',
    ticket_value: 'Valoarea tichetului',
  },
  updated_budget_types: {
    import: 'Import',
    consume: 'Consum',
    cron_job: 'Sistem',
    manual: 'Editare individuala',
  },
  tooltip: {
    default_value: 'Daca nicio valoarea specifica nu este setata, valoarea globala implicita va fi atribuita',
  },
  request: {
    number: "Nr solicitare",
    placed_on: "Plasata la data",
    amount: "Valoare",
    status: "Starea solicitarii",
    order_number: "Numar comanda",
    order_placed_on: "Comanda plasata la",
    order_status: "Starea comenzii",
    reject_order: "Anulare solicitare"
  },
  status_change_successfully: "Statusul a fost schimbat cu succes",
  invitation_sent_successfully: "Invitatia a fost trimisa cu succes",
  change_status_to_invited_success: "Statutul beneficiarului a fost modificat cu succes.",
  pending_order_requests: "Beneficiarul are active solicitari ce nu au fost plasate inca. ",
  reActivateBeneficiary_success: "Beneficiarul a fost reactivat cu succes.",
  invitation_sent_error: "Nu s-a putut trimite invitatia. Te rugam sa incerci mai tarziu",
  change_status_to_invited: "Nu s-a putut modifica statusul. Te rugam sa incerci mai tarziu",
  change_status_error: "Nu s-a putut modifica statusul. Te rugam sa incerci mai tarziu",
  entries: "Intrari",
  modal: {
    this_will_remove_the_beneficiary_from: [
      "Aceasta actiune va elimina beneficiarul de la",
      "Aceasta actiune va elimina {count} beneficiari de la"
    ],
    any_available_budget: [
      "Orice buget disponibil in contul beneficiarului va fi eliminat",
      "Orice buget disponibil in contul beneficiarilor va fi eliminat"
    ],
    this_action_cannot_be_reverted: "aceasta actiune este ireversibila",
    this_will_suspend_the_recipient_from: [
      "Aceasta actiune va suspenda beneficiarul de la <span class=\"text-bold\"> {companyName} </span> imediat.",
      "Aceasta actiune va suspenda {count} beneficiari de la <span class=\"text-bold\"> {companyName} </span> imediat."
    ],
    you_will_still_be_able: "Vei putea totusi sa accesezi si sa actualizezi informatiile lor, dar licenta de utilizator corespunzatoare nu va fi contorizata.",
    during_suspension_the_beneficiary: [
      "In timpul suspendarii, beneficiarul nu va avea acces la contul companiei sale, inclusiv la bugete sau la completari. Aceasta actiune nu va afecta comenzile in curs",
      "In timpul suspendarii, beneficiarii nu vor avea acces la contul companiei sale, inclusiv la bugete sau la completari. Aceasta actiune nu va afecta comenzile in curs"
    ],
    this_action_can_be_reverted_at_any_time: "Aceasta actiune poate fi inversata in orice moment",
    this_will_reactivate_the_beneficiary: [
      "Aceasta actiune va reactiva beneficiarul de la <span class=\"text-bold\"> {companyName} </span> imediat.",
      "Aceasta actiune va reactiva beneficiarii selectati de la <span class=\"text-bold\"> {companyName} </span> imediat. "
    ],
    this_will_change_status_to_invited: 'Această acțiune va șterge toate datele de autentificare asociate cu beneficiarul. Aceasta înseamnă că beneficiarul nu va mai avea acces cu credențialele actuale și va fi invitat să completeze informațiile necesare pentru autentificare',
    this_will_reactivate_user: 'Esti sigur ca vrei sa reactivezi userul?',
    an_email_invitation_will: "Un email de invitatie va fi trimis la adresa de email setata in campul de invitatie pentru <span class=\"text-bold\">{companyName}</span>.",
    if_you_have_changed_the_email: "Daca ai schimbat email-ul, asigura-te ca l-ai salvat.",
    are_you_sure_you_want_to_save_the_changes: "Esti sigur ca vrei sa salvezi modificarile?",
    the_information_has_been_changed: "Exista informatii modificate care nu au fost salvate. Confirmi parasirea paginii?",
    could_not_change: 'Nu s-au putut schimba statusurile beneficiarilor!',
    statuses_changed: [
      '{count} status a fost schimbat',
      '{count} statusuri au fost schimbate',
    ],
  },
  mass_actions: {
    selected_count: 'Numar de elemente selectate ',
    not_found_count: 'Numar de elemente negasite ',
    btn_bulk_actions: 'Actiuni in Masa',
    btn_download_example: 'Descarca Exemplu Import',
    btn_upload_import: 'Incarca Import',
    btn_export: 'Exporta beneficiari',
    messages: {
      malformed_file: 'Fisierul de import nu respecta macheta sau campurile nu sunt formatate corect',
      import_success: 'Fisier importat cu succes',

    }
  },
  consumed_days: 'Consumat',
  btn: {
    save_budget: 'Salveaza buget',
    save_telework_budget: 'Salveaza buget telemunca',
    save_ppp3_budget: 'Salveaza buget PPP3',
    save_time_sheet: 'Salveaza pontaj',
  },
  titles: {
    budget: 'Buget flexibil',
    telework_budget: 'Buget telemunca',
    ppp3_budget: 'Buget PPP3',
  },
  company: {
    taxation_subtitle: 'Taxare',
    gross_budget_taxation_salary: 'Salariu',
    gross_budget_taxation_budget: 'Buget',
    gross_budget_taxation_subtitle: 'Brut',
    net_budget_taxation_subtitle: 'Net',
  }
}
