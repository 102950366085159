<template>
  <div class="show modal fade">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header w-100 no-border pb-0">
          <h3 class="float-left">{{ $t('flexben.general.edit') }} {{ $t('flexben.import.types.ppp3') }}</h3>
          <span :class="'import-status-item-'+itemData.status" class="float-right my-auto">{{ $t('flexben.import.item_statuses.'+itemData.status) }}</span>
        </div>
        <div class="modal-body py-0 bg-white">
          <div v-if="itemData.invalid_reasons !== null" class="row no-gutters text-danger">
            <div class="col-12 mb-1"
                 v-html="getInvalidReasonsPpp3(itemData.invalid_reasons)">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="first_name">
                {{ $t('flexben.import.table_headings.first_name') }}</label>
              <input id="first_name" v-model="itemData.first_name"
                     :class="{'border-danger':checkInErrorBag('first_name')}"
                     class="form-control m-0 w-100 background-white"
                     type="text">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="last_name">
                {{ $t('flexben.import.table_headings.last_name') }}</label>
              <input id="last_name" v-model="itemData.last_name"
                     :class="{'border-danger':checkInErrorBag('last_name')}"
                     class="form-control m-0 w-100 background-white"
                     type="text">
            </div>
            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="cnp">
                {{ $t('flexben.import.table_headings.cnp') }}</label>
              <input id="cnp" v-model="itemData.cnp"
                     :class="{'border-danger':checkInErrorBag('cnp')}"
                     class="form-control m-0 w-100 background-white" type="text">
            </div>

            <div class="col-12 col-md-6 form-group">
              <label class="m-0 form-fields-required col-form-label" for="budget">
                {{ $t('flexben.import.table_headings.contract_number') }}</label>
              <input id="contract_number" v-model="itemData.contract_number"
                     :class="{'border-danger':checkInErrorBag('contract_number')}"
                     class="form-control m-0 w-100 background-white" type="number">
            </div>
            <div class="col-12 col-md-12 form-group">
              <label class="m-0 form-fields col-form-label" for="benefit_name">
                {{ $t('flexben.import.table_headings.benefit_name') }}</label>
              <input id="benefit_name" v-model="itemData.benefit_name"
                     class="form-control m-0 w-100 background-white"
                     disabled type="text">
            </div>
            <div class="col-12 col-md-4 form-group mt-3">
              <label class="m-0 form-fields col-form-label mb-1" for="benefit_name">
                {{ $t('flexben.import.table_headings.contribution_type') }}</label>
              <input id="benefit_name" v-model="itemData.contribution_type"
                     class="form-control m-0 w-100 background-white"
                     disabled type="text">
            </div>
            <div class="col-12 col-md-4 form-group">
              <label class="m-0 col-form-label" for="budget">
                {{ $t('flexben.import.table_headings.company_contribution_value') }}</label>
              <input id="company_contribution_value" v-model="itemData.company_contribution_value"
                     :class="{'border-danger':checkInErrorBag('company_contribution_value')}"
                     class="form-control m-0 w-100 background-white" type="number">
            </div>
          </div>
        </div>
        <div class="modal-footer mb-2 no-border w-100 d-inline">
          <button class="btn text-danger float-left" @click="dropImportItem">
            <font-awesome-icon icon="trash-alt"/>
            {{ $t('flexben.general.delete') }}
          </button>
          <button class="btn save-btn float-right" @click="updateImportItem">{{ $t('flexben.general.btn.save') }}
          </button>
          <button class="btn float-right text-themed" @click="closeModal">{{ $t('flexben.general.btn.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "EditPpp3Modal",
  components: {},
  props: {
    importType: {
      type: String,
      required: true
    },
    item_data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
  },
  data() {
    return {
      initialMount: true,
    };
  },
  watch: {},
  computed: {
    itemData: function () {
      return this.item_data;
    }
  },
  mounted() {
  },
  methods: {
    updateImportItem() {
      this.itemData.personal_contribution_value = parseFloat(this.itemData.personal_contribution_value);
      this.itemData.company_contribution_value = parseFloat(this.itemData.company_contribution_value);
      this.$emit('updateImportItem');
    },
    closeModal() {
      this.$emit('closeModal');
    },
    dropImportItem() {
      this.$emit('dropImportItem');
    },
    changeDate() {
    },
    getInvalidReasonsPpp3(invalidReasons) {
      invalidReasons = JSON.parse(invalidReasons);
      let errorList = [];
      let indexKey, errorField;
      for (errorField in invalidReasons) {
        if (typeof invalidReasons[errorField] === 'string') {
          errorList.push(this.$t('flexben.formFieldError', {
            field: this.$t('flexben.import.table_headings.' + errorField),
            reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField])
          }))
        } else {
          for (indexKey in invalidReasons[errorField]) {
            errorList.push(this.$t('flexben.formFieldError', {
              field: this.$t('flexben.import.table_headings.' + errorField),
              reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField][indexKey])
            }))
          }
        }
      }
      return errorList.join('<br>');
    },
    checkInErrorBag(field) {
      if (this.itemData.invalid_reasons == null) {
        return false;
      }
      let objKeys = Object.keys(this.itemData.invalid_reasons);

      return objKeys.includes(field);
    }
  }
}
</script>
