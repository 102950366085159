<template>
  <b-tab :title="$t('flexben.clients.benefits.pp3')" :key="tabIndex"
         :class="'container-bg-white pt-2'">
    <div>
      <div class="row pt-2 px-3">
        <div class="col-12">
          <span class="text-20 text-bold">{{$t('flexben.clients.benefits.pp3')}}</span>
        </div>
      </div>
      <div class="row mt-4 mx-1">
        <div class="col-12">
          <div class="container-fluid px-0 m-0">
            <div class="table-responsive hover-table">
              <table class="table no-min-width">
                <thead>
                <tr>
                  <th>{{ $t("flexben.benefit_groups.detail.ben_name") }}</th>
                  <th class="d-none d-md-table-cell">{{ $t("flexben.benefit_groups.detail.contribution.client") }}</th>
                  <th v-if="showPersonalContributions" class="d-none d-md-table-cell">{{ $t("flexben.benefit_groups.detail.contribution.personal") }}</th>
                  <th v-if="showPersonalContributions"class="d-none d-sm-table-cell">{{$t("flexben.clients.partial.ppp3_enable_benefit_for_client")}}</th>
                  <th v-if="isAdmin" class="w-50-p"></th>
                </tr>
                </thead>
                <tbody v-if="benefits.length > 0">
                <tr v-for="(benefit, index) in benefits" :key="index"
                    class="trhover">
                  <td class="d-flex align-items-center">
                    <img class="flx-table-image mr-2" :src="benefit.image_url" loading="lazy"/>
                    <span :style="{backgroundColor: benefit.color}"
                          class="color-bullet mr-2"></span> {{benefit.name }}
                  </td>
                  <td class="d-none d-md-table-cell links-container">
                    <div class="form-group mb-1 mt-2">
                      <div v-if="benefit.benefit_configurations &&
                                 benefit.benefit_configurations[0] &&
                                 benefit.benefit_configurations[0].configuration &&
                                 'contribution' in benefit.benefit_configurations[0].configuration">
                        <select v-model="benefit.benefit_configurations[0].configuration['contribution']['client']"
                                id="client" class="form-control">
                          <option v-for="type of (formOptions.ppp3_contributions)" :value="type" :key="type">{{ type }}</option>
                        </select>
                      </div>
                    </div>
                    <br>
                  </td>
                  <td v-if="showPersonalContributions" class="d-none d-md-table-cell links-container" >
                    <div class="form-group mb-1 mt-2" v-if="benefit.benefit_configurations &&
                        benefit.benefit_configurations[0] &&
                        benefit.benefit_configurations[0].configuration &&
                        'contribution' in benefit.benefit_configurations[0].configuration">
                      <div>
                        <select v-model="benefit.benefit_configurations[0].configuration['contribution']['personal']"
                                id="personal" class="form-control">
                          <option v-for="type of (formOptions.ppp3_contributions)" :value="type" :key="type">{{ type }}</option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td v-if="showPersonalContributions" class="d-none d-sm-table-cell links-container text-center" style="width: 11%">
                    <div class="form-group mb-1 mt-2">
                      <div v-if="benefit.benefit_configurations &&
                                 benefit.benefit_configurations[0] &&
                                 benefit.benefit_configurations[0].configuration &&
                                 'personal_contribution_active' in benefit.benefit_configurations[0].configuration">
                        <b-form-checkbox class="d-inline-block"
                                         switch
                                         v-model="benefit.benefit_configurations[0].configuration['personal_contribution_active']">
                        </b-form-checkbox>
                      </div>
                    </div>
                    <br>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="2" class="text-center trhover gri-cell">
                    {{
                    $t("flexben.benefit_groups.no_benefits_available",{type:$t('flexben.benefit_groups.benefit_type_flexible')})
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="mb-4 col-12">
          <button @click="submitSettings()" class="btn save-btn px-5 float-right">{{ $t("flexben.general.btn.save") }}
          </button>
        </div>
      </div>

    </div>

  </b-tab>
</template>

<script>

import {BFormCheckbox, BTab, BTooltip} from "bootstrap-vue";
import httpFlexbenService from "@/http/http-flexben";

export default {
  name: "TabPP3",
  components: {
    "b-tab": BTab,
    "b-form-checkbox": BFormCheckbox,
  },

  props: {
    benefits: {
      type: Array,
      default: function () {
        return []
      },
      required: true
    },
    tabIndex: {
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: false,
      required: true
    },
    clientId: {
      type: Number,
      required: true
    },
    formOptions: {
      ppp3_contributions: []
    },
    form: {
      configurations: []
    }
  },
  data: function () {
    return {
      httpService: httpFlexbenService.clients,
    }
  },
  computed: {
    personalContributionChange() {
      return this.benefits
        .filter(benefit =>
          benefit.benefit_configurations &&
          benefit.benefit_configurations.length > 0 &&
          benefit.benefit_configurations[0].configuration &&
          'personal_contribution_active' in benefit.benefit_configurations[0].configuration)
        .map(benefit => benefit.benefit_configurations[0].configuration['personal_contribution_active'])
    },
    // TODO: Temporarily hiding personal_ppp3 from the UI.
    // This is done to remove this feature visually but keep it functional
    // in case management decides to reintroduce it. Revisit if management confirms its removal.
    showPersonalContributions(){
      return false;
    }
  },
  watch: {
    personalContributionChange() {}
  },
  methods: {
    toggleLoading(loading, loaderError = false) {
      this.$emit('toggleLoading', loading, loaderError)
    },
    submitSettings() {
      this.toggleLoading(true);
      let benefits = {benefits: this.benefits};
      this.httpService.updateClientBenefitConfig(this.clientId, benefits)
        .then((response) => {
          this.$emit('refreshData');

      })
        .catch((error) => {
          console.log(error);
          this.toggleLoading(false);
        });
    },
  }
}
</script>

<style scoped>

</style>
