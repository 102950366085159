<template>
  <div class="show modal fade">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header w-100 no-border pb-0">
          <h3 class="float-left">{{ $t('flexben.general.edit') }} {{ $t('flexben.import.types.beneficiary') }}</h3>
          <span class="float-right my-auto" :class="'import-status-item-'+itemData.status">{{ $t('flexben.import.item_statuses.'+itemData.status) }}</span>
        </div>
        <div class="modal-body py-0 bg-white">
          <div class="row text-danger" v-if="itemData.invalid_reasons !== null">
            <div class="col-12 mb-1"
                 v-html="getInvalidReasons(itemData.invalid_reasons)">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="first_name">
                {{ $t('flexben.import.table_headings.first_name') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="first_name"
                     :class="{'border-danger':checkInErrorBag('first_name')||checkInErrorBag('name_mix')}"
                     v-model.trim="itemData.first_name">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="last_name">
                {{ $t('flexben.import.table_headings.last_name') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="last_name"
                     :class="{'border-danger':checkInErrorBag('last_name')||checkInErrorBag('name_mix')}"
                     v-model.trim="itemData.last_name">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="invitation_email">
                {{ $t('flexben.import.table_headings.invitation_email') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="invitation_email"
                     :class="{'border-danger':checkInErrorBag('invitation_email')}"
                     v-model.trim="itemData.invitation_email">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="phone_number">
                {{ $t('flexben.import.table_headings.phone_number') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="phone_number"
                     :class="{'border-danger':checkInErrorBag('phone_number')}"
                     v-model.trim="itemData.phone_number">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="cnp">
                {{ $t('flexben.import.table_headings.cnp') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="cnp"
                     :class="{'border-danger':checkInErrorBag('cnp')}"
                     v-model.trim="itemData.cnp">
            </div>
            <div class="col-md-6 col-xl-4 form-group mb-0">
              <label for="invitation_channel" class="col-form-label">
                {{ $t('flexben.import.table_headings.invitation_channel') }}</label>
              <select name="invitation_channel" id="invitation_channel"
                      class="form-control w-100 m-0"
                      :class="{'border-danger':checkInErrorBag('invitation_channel')}"
                      v-model="itemData.invitation_channel">
                <option :value="null">-</option>
                <option v-for="(value, index) in ['email','sms']"
                        :key="index"
                        :value="value">
                  {{ value }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="benefit_category_code">
                {{ $t('flexben.import.table_headings.benefit_category_code') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="benefit_category_code"
                     :class="{'border-danger':checkInErrorBag('benefit_category_code')}"
                     v-model.trim="itemData.benefit_category_code">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="employee_number">
                {{ $t('flexben.import.table_headings.employee_number') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="employee_number"
                     :class="{'border-danger':checkInErrorBag('employee_number')}"
                     v-model="itemData.employee_number">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="department">
                {{ $t('flexben.import.table_headings.department') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="department"
                     :class="{'border-danger':checkInErrorBag('department')}"
                     v-model="itemData.department">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="job_title">
                {{ $t('flexben.import.table_headings.job_title') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="job_title"
                     :class="{'border-danger':checkInErrorBag('job_title')}"
                     v-model="itemData.job_title">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="cost_center">
                {{ $t('flexben.import.table_headings.cost_center') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="cost_center"
                     :class="{'border-danger':checkInErrorBag('cost_center')}"
                     v-model="itemData.cost_center">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="working_point">
                {{ $t('flexben.import.table_headings.working_point') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="working_point"
                     :class="{'border-danger':checkInErrorBag('working_point')}"
                     v-model="itemData.working_point">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="underage_children_nr">
                {{ $t('flexben.import.table_headings.underage_children_nr') }}</label>
              <input type="number" min="0" class="form-control m-0 w-100 background-white" id="underage_children_nr"
                     :class="{'border-danger':checkInErrorBag('underage_children_nr')}"
                     v-model="itemData.underage_children_nr">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="up_ro_address_id">
                {{ $t('flexben.import.table_headings.up_ro_address_id') }}</label>
              <select name="company_address" id="up_ro_address_id"
                      class="form-control m-0 w-100 background-white"
                      :class="{'border-danger':checkInErrorBag('up_ro_address_id')}"
                      v-model="itemData.up_ro_address_id">
                <option :value="id" v-for="(address, id) in filters.company_addresses.values" :key="id">
                  {{ id +' - '+address}}
                </option>
              </select>
            </div>

            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="up_dejun_enable_auto_topup">
                {{ $t('flexben.import.table_headings.up_dejun_enable_auto_topup') }}</label>
              <div class="d-flex mt-2 ">
                <span class="mr-3 my-auto">{{ $t('flexben.general.no') }}</span>
                <b-form-checkbox id="up_dejun_enable_auto_topup" class="cursor-pointer"
                                 switch
                                 :disabled="!importFormOptions.up_dejun_enabled"
                                 v-model="itemData.up_dejun_enable_auto_topup">
                </b-form-checkbox>
                <span class="ml-3 my-auto">{{ $t('flexben.general.yes') }}</span>
              </div>
            </div>

            <div class="col-12 col-md-6 col-xl-4 form-group mb-0"
                 v-if="importFormOptions.up_dejun_enabled">
              <label for="up_dejun_ticket_value"
                     class="m-0 col-form-label">
                <template v-if="!importFormOptions.top_up_set_ticket_nr.enable">
                  {{ $t('flexben.import.table_headings.up_dejun_ticket_value') }}
                </template>
                <template v-if="importFormOptions.top_up_set_ticket_nr.enable">
                  {{ $t('flexben.import.table_headings.up_dejun_ticket_count') }}
                </template>

              </label>
              <select v-if="importFormOptions.top_up_fixed_values.enable"
                      id="up_dejun_ticket_value"
                      v-model="itemData.up_dejun_ticket_value"
                      :disabled="!itemData.up_dejun_enable_auto_topup"
                      class="form-control"
                      :class="{'border-danger':checkInErrorBag('up_dejun_ticket_value')}">
                <option v-for="(value, index) of importFormOptions.top_up_fixed_values.values"
                        :value="value"
                        :key="index">{{ value }}
                </option>
              </select>
              <input
                v-if="!importFormOptions.top_up_fixed_values.enable  && !importFormOptions.top_up_set_ticket_nr.enable"
                type="number" step="0.01" class="form-control m-0 w-100 background-white"
                id="up_dejun_ticket_value"
                :class="{'border-danger':checkInErrorBag('up_dejun_ticket_value')}"
                :disabled="!itemData.up_dejun_enable_auto_topup"
                v-model="itemData.up_dejun_ticket_value"/>

              <select v-if="importFormOptions.top_up_set_ticket_nr.enable"
                      v-model="itemData.up_dejun_ticket_count"
                      :disabled="!itemData.up_dejun_enable_auto_topup"
                      :class="{'border-danger':checkInErrorBag('up_dejun_ticket_count')}"
                      class="form-control" id="up_dejun_ticket_value">
                <option :value="null">-</option>
                <option v-for="item in generateRange(5, 25)" :value="item" :key="item">{{ item }}</option>
              </select>
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0 mt-3">
              <label class="m-0 col-form-label" for="income_tax_percent">
                {{ $t('flexben.import.table_headings.income_tax_percent') }}</label>
              <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
                     id="income_tax_percent"
                     :class="{'border-danger':checkInErrorBag('income_tax_percent')}"
                     v-model.trim="itemData.income_tax_percent">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0 mt-3">
              <label class="m-0 col-form-label" for="cass_percent">
                {{ $t('flexben.import.table_headings.cass_percent') }}</label>
              <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
                     id="cass_percent"
                     :class="{'border-danger':checkInErrorBag('cass_percent')}"
                     v-model.trim="itemData.cass_percent">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="cas_percent">
                {{ $t('flexben.import.table_headings.cas_percent') }}</label>
              <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
                     id="cas_percent"
                     :class="{'border-danger':checkInErrorBag('cas_percent')}"
                     v-model.trim="itemData.cas_percent">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="fixed_benefit_group">
                {{ $t('flexben.import.table_headings.fixed_benefit_group') }}</label>
              <input type="text" class="form-control m-0 w-100 background-white" id="fixed_benefit_group"
                     :class="{'border-danger':checkInErrorBag('fixed_benefit_group_code')}"
                     v-model.trim="itemData.fixed_benefit_group_code">
            </div>

            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="ppp3_deduction_limit">
                {{ $t('flexben.import.table_headings.ppp3_deduction_limit') }}</label>
              <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
                     id="ppp3_deduction_limit"
                     :class="{'border-danger':checkInErrorBag('ppp3_deduction_limit')}"
                     v-model.number="itemData.ppp3_deduction_limit">
            </div>

            <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
              <label class="m-0 col-form-label" for="medical_insurance_deduction_limit">
                {{ $t('flexben.import.table_headings.medical_insurance_deduction_limit') }}</label>
              <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
                     id="medical_insurance_deduction_limit"
                     :class="{'border-danger':checkInErrorBag('medical_insurance_deduction_limit')}"
                     v-model.number="itemData.medical_insurance_deduction_limit">
            </div>
            <div class="col-12 col-md-6 col-xl-4 form-group mb-0 mt-3">
              <label class="m-0 col-form-label" for="gym_membership_threshold_limit">
                {{ $t('flexben.import.table_headings.thresholds.gym_membership') }}</label>
              <input type="number" min="0" max="100" step="0.01" class="form-control m-0 w-100 background-white"
                     id="gym_membership_threshold_limit"
                     :class="{'border-danger':checkInErrorBag('gym_membership_threshold_limit')}"
                     v-model.number="itemData.gym_membership_threshold_limit">
            </div>
          </div>
        </div>

        <div class="modal-footer mb-2 no-border w-100 d-inline">
          <button class="btn text-danger float-left" @click="dropImportItem">
            <font-awesome-icon icon="trash-alt" class="mr-2"/>
            {{ $t('flexben.general.delete') }}
          </button>
          <button class="btn save-btn float-right" @click="updateImportItem">{{ $t('flexben.general.btn.save') }}
          </button>
          <button class="btn float-right text-themed" @click="closeModal">{{ $t('flexben.general.btn.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox} from "bootstrap-vue";

export default {
  name: "EditUpdateBeneficiariesModal",
  components: {
    "b-form-checkbox": BFormCheckbox,
  },
  props: {
    item_data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
    filters: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
    importFormOptions: {
      required: true,
      type: Object,
      default: function () {
        return {
          invitation_channels: ['email','sms'],
          top_up_fixed_values: {
            enable: false,
            values: []
          },
          top_up_set_ticket_nr: {
            enable: false,
            ticket_value: null,
          },
          up_dejun_enabled: false
        };
      }
    },
  },
  computed: {
    itemData: function () {
      return this.item_data;
    }
  },
  methods: {
    updateImportItem() {
      this.$emit('updateImportItem');
    },
    closeModal() {
      this.$emit('closeModal');
    },
    dropImportItem() {
      this.$emit('dropImportItem');
    },
    getInvalidReasons(invalidReasons) {
      return this.$parent.getInvalidReasons(invalidReasons)
    },
    checkInErrorBag(field) {
      if (this.itemData.invalid_reasons == null) {
        return false;
      }

      let objKeys = Object.keys(this.itemData.invalid_reasons);
      return objKeys.includes(field);
    }
  }
}
</script>
