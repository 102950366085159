import i18n from "@/plugins/language/i18n";
import httpFlexbenService from "@/http/http-flexben";
import moment from "moment";
import FormErrorList from "@/components/fragments/FormErrorList";
import BenefitVoucherFormFields from "./partials/BenefitVoucherFormFields";
import BenefitSubscriptionFormFields from "./partials/BenefitSubscriptionFormFields";
import BenefitFiscalAvailability from "@/components/flexben/benefits/partials/BenefitFiscalAvailability";
import FormButtons from "@/components/flexben/benefits/partials/FormButtons";
import ConfirmModal from "@/components/flexben/benefits/modals/ConfirmModal";
import BenefitPeriodAvailability from "@/components/flexben/benefits/partials/BenefitPeriodAvailability";
import LegislatedSpecificValues from "@/components/flexben/benefits/partials/LegislatedSpecificValues";
import BenefitGiftTabs from "@/components/flexben/benefits/partials/BenefitGiftTabs";
import BenefitPpp3FormFields from "./partials/BenefitPpp3FormFields";
import BenefitMedicalInsurance from "./partials/BenefitMedicalInsurance";
import {BFormCheckbox, BTooltip} from "bootstrap-vue";
import BenefitWorldClass from "@/components/flexben/benefits/partials/BenefitWorldClass";
import vueEditorSettings from "@/constants/vue_editor_settings";
import {VueEditor} from "vue2-editor";

export default {
  name: "BenefitForm",
  components: {
    BenefitGiftTabs,
    LegislatedSpecificValues,
    BenefitPeriodAvailability,
    ConfirmModal,
    FormButtons,
    BenefitFiscalAvailability,
    "form-error-list": FormErrorList,
    "BenefitVoucherFormFields": BenefitVoucherFormFields,
    "BenefitSubscriptionFormFields": BenefitSubscriptionFormFields,
    "BenefitPpp3FormFields": BenefitPpp3FormFields,
    "BenefitMedicalInsurance": BenefitMedicalInsurance,
    "BenefitWorldClass": BenefitWorldClass,
    "b-form-checkbox": BFormCheckbox,
    'v-editor': VueEditor,
    'b-tooltip': BTooltip
  },
  props: {
    formAction: {
      type: String,
      required: true
    },
    itemId: {
      type: Number,
    }
  },
  data: function () {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      showForm: false,
      isFormModified: false,
      isParent : false,
      locale: i18n.locale,
      httpService: httpFlexbenService.benefitsService,
      brandHttpService: httpFlexbenService.brandService,
      countryHttpService: httpFlexbenService.countryService,
      modal: {
        action: '',
        subOperation: '',
        open: false,
        formSuccess: false,
        message: '',
        options: {
          saveAs: '',
          statusChange: '',
        }
      },
      dateFormat: 'dd/MM/yyyy',
      options: {
        available_languages: [],
        categories: [],
        categories_client: [],
        sub_types: [
          "voucher",
          "subscription"
        ],
        child_sub_types: [],
        subscription_periods: [],
        delivery_types: [],
        form_builder_versions: [],
        partners: [],
        taxes: {},
        thresholds: [],
        counties: [],
        available_systems: [],
        county_availability: [0, 1],
        country_availability: [],
        brand_availability: [],
        location_availability: 0,
        budget_consumption_types: [],
        importTypes: [
          'prices',
          'locations',
          'prices_locale'
        ],
      },
      errorsBag: {},
      imageChanged: {
        image: false,
      },
      image_preview: {
        image: null,
      },
      futureValuesDisableUntil: {to: moment().toDate()},
      formData: {
        id: null,
        is_legislated: false,
        fiscal_treatment: null,
        status: 'draft',
        name: '',
        invoice_name: '',
        crm_code: '',
        product_link: '',
        parent_id : null,
        partner_id: null,
        rationale: [],
        description: [],
        children: [],
        global_variable_codes: [],
        thresholds: [],
        color: '#000000',
        sub_type: null,
        start_use_date: null,
        form_builder_version: 1,
        end_use_day_month: {day: 31, month: 12},
        image: null,
        icon: null,
        image_url: httpFlexbenService.globalVariablesService.baseImagePreview,
        icon_url: httpFlexbenService.globalVariablesService.baseImagePreview,
        category_ids: [],
        promoted_as_new: false,
        county_availability: 0,
        country_availability: null,
        brand_availability: null,
        location_availability: 0,
        vat: null,
        county_ids: [],
        systems: ['web'],
        budget_consumption_types: [],

        future_values: {values: {}},
        data: {values: {}}, //basically properties
        dynamic_fields: {},
        can_edit_budget_consumption_type: true,
      },
      worldClassImportType: null,
      worldClassImportErrorMessage: null,
      worldClassImportData: {
        file: null,
        fileName: null
      },
      subType: null,
      dynamicForm: {},
      dynamicFormKey: 0,
      assigned_categories: [],
      colorToggleClass: false,
      selected_counties: [],
      child_sub_type: [],
      selected_systems: ['web'],
      editorToolbar: vueEditorSettings.editorToolbar
    };
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        this.isFormModified = true;
      }
    },
    subType: {
      handler: function (type) {
        this.formData.sub_type = type;
        switch (type) {
          case 'voucher':
            this._buildVoucherFrom();
            break;
          case 'subscription':
            this._buildSubscriptionFrom();
            break;
          case 'ppp3':
            this._buildPpp3Form();
            break;
          case 'medical_insurance':
            this._buildMedicalInsuranceForm();
            break;
          case 'subscription_world_class':
            this._buildSubscriptionWorldClassForm();
            break;
          case 'points_pago':
            this._buildPointsPagoForm();
            break;
          default:
            this.dynamicForm = {
              dynamic_fields: this._getDynamicFieldString()
            };
            break;
        }
      }
    },
    selected_counties: {
      deep: true,
      handler: function (v) {
        let ids = [];
        for (let obj of v) {
          ids.push(obj.id);
        }
        this.formData.county_ids = ids;
      }
    },
    selected_systems: {
      deep: true,
      handler: function (v) {
        this.formData.systems = [];
        for (let obj of v) {
          this.formData.systems.push(obj);
        }
      }
    },
    worldClassImportType: {
      handler: function (value) {
        this.worldClassImportErrorMessage = null;
        this.worldClassImportData = {
          file: null,
          fileName: null
        };
      }
    }
  },
  computed: {
    exampleImportLink: function () {
      return this.httpService.generateImportExampleUrl(this.worldClassImportType);
    }
  },
  created() {
    if (this.formAction === 'create') {
      this._getOptions();
    } else if (this.formAction === 'update' && this.itemId) {
      this._getBenefit();
    } else {
      this.goBack();
    }
    this._getBrandList();
    this._getCountryList();
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    goBack() {
      if (this.isFormModified) {
        this.modalAct('go_back')
      } else {
        this.$emit('clearForm');
      }
    },
    modalAct(action, statusToChange = '') {
      this.modal.message = '';
      if (action === 'draft' || action === 'publish' || action === 'save') {
        this.modal.action = 'form_submit';
        this.modal.subOperation = 'form_submit';
        this.modal.options.saveAs = action;
      } else if (action === 'status_change') {
        this.modal.action = action;
        this.modal.options.statusChange = statusToChange;
      } else if (action === 'delete') {
        this.modal.message = this.$t('flexben.benefit.modal.irreversible_action');
        this.modal.action = 'delete';
      } else {
        this.modal.action = action;
      }
      this.modal.open = true;
    },
    closeModal() {
      this.modal.action = '';
      this.modal.options.statusChange = '';
      this.modal.options.saveAs = '';
      this.modal.message = '';
      this.modal.open = false;
    },
    confirmAction(action) {
      this.modal.message = '';
      if (action === 'go_back') {
        this.closeModal();
        this.isFormModified = false;
        this.goBack();
      }
      if (action === 'form_submit') {
        this.loading = true;
        this.errorsBag = {};
        this.modal.open = false;
        if (this.formAction === 'update') {
          this._updateItem();
        } else if (this.formAction === 'create') {
          this._createItem();
        }
      }
      if (this.modal.action === 'status_change') {
        this.loading = true;
        this.errorsBag = {};
        this.modal.open = false;
        this._changeStatus();
      }

      if (this.modal.action === 'delete') {
        this.loading = true;
        this.errorsBag = {};
        this.modal.open = false;
        this._deleteItem();
      }
      if (action === 'end') {
        this.closeModal();
        if (this.modal.formSuccess) {
          this.$emit('clearForm', true);
        }
      }
    },
    setFile(refTarget, fileType, formKey) {
      this.errorsBag = {};
      if (fileType === 'image' || fileType === 'icon') {
        if (this.$refs[refTarget].accept.indexOf(this.$refs[refTarget].files[0].type.split('/')[1]) > 0) {
          let reader = new FileReader();
          reader.readAsDataURL(this.$refs[refTarget].files[0]);
          reader.onload = evt => {
            let img = new Image();
            img.onload = () => {
              if (refTarget === 'benefit_icon') {
                this.formData[formKey] = this.$refs[refTarget].files[0];
                this.imageChanged[formKey] = true;
                this.image_preview[formKey] = URL.createObjectURL(this.formData[formKey]);
              } else {
                if (img.width !== img.height) {
                  this.errorsBag = {image: 'image_square'};
                } else if (img.width !== 500 || img.height !== 500) {
                  this.errorsBag = {image: 'image_500_500'};
                } else {
                  this.formData[formKey] = this.$refs[refTarget].files[0];
                  this.imageChanged[formKey] = true;
                  if (typeof this.formData[formKey] !== "undefined") {
                    this.image_preview[formKey] = URL.createObjectURL(this.formData[formKey]);
                  }
                }
              }
            }
            img.src = evt.target.result;
          }
        } else {
          this.errorsBag = {image: 'image_type_supported'}
        }
      } else if (fileType === 'file') {
        this.invalidImportReason = null;
        this.formData[formKey] = this.$refs[refTarget].files[0];
        // this.importFileName = this.code_value.name;
      }
    },
    assignCategory(index, type) {
      if (type === 'client') {
        this.assigned_categories.push(this.options.categories_client[index]);
        this.formData.category_ids.push(this.options.categories_client[index].id);
        return;
      }

      this.assigned_categories.push(this.options.categories[index]);
      this.formData.category_ids.push(this.options.categories[index].id);

    },
    unAssignCategory(index) {
      let indexOfc = this.formData.category_ids.indexOf(this.assigned_categories[index].id);
      this.formData.category_ids.splice(indexOfc, 1)
      this.assigned_categories.splice(index, 1);
    },
    exampleLink() {
      return this.httpService.dynamicFieldsExampleUrl;
    },
    toggleBudgetConsumptionType(code) {
      if (this.formData.budget_consumption_types.includes(code)) {
        let index = this.formData.budget_consumption_types.indexOf(code)
        this.formData.budget_consumption_types.splice(index, 1)
      } else {
        this.formData.budget_consumption_types.push(code)
      }
    },
    worldClassUploadFile(event) {
      this.worldClassImportData.file = event.target.files[0];
      this.worldClassImportData.fileName = event.target.files[0].name;
    },
    uploadWorldClassImport() {
      this.worldClassImportErrorMessage = null;
      if (this.worldClassImportData.file == null) {
        return;
      }
      let payload = new FormData();
      payload.append('file', this.worldClassImportData.file);
      this.loading = true;
      this.httpService.import(this.worldClassImportType, payload)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === 'error') {
            this.worldClassImportErrorMessage = response.data.message;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.worldClassImportErrorMessage = 'Invalid import format';
          this.loading = false;
        });
    },
    triggerImportInput() {
      if (this.worldClassImportType) {
        this.$refs['file_input'].click();
      }
    },
    _getOptions() {
      this.loading = true;
      this.httpService.options()
        .then((response) => {
          this._setFormOptions(response.data);
          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.initialLoading = false;
          this.loading = false;
        })
        .then(() => {
          this.isFormModified = false;
        });
    },
    _getBenefit() {
      this.loading = true;
      this.httpService.details(this.itemId)
        .then((response) => {
          this._setFormOptions(response.data);
          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.initialLoading = false;
          this.loading = false;
        })
        .then(() => {
          this.isFormModified = false;
        });
    },
    _getBrandList() {
      this.brandHttpService.all().then((response) => {
        response.data.forEach((value) => {
          this.options.brand_availability.push(value);
        });
      }).catch(() => {
        this.initialLoading = false;
        this.loading = false;
      });
    },
    _getCountryList() {
      this.countryHttpService.all().then((response) => {
        response.data.forEach((value) => {
          this.options.country_availability.push(value);
        });
      }).catch(() => {
        this.initialLoading = false;
        this.loading = false;
      });
    },
    _setFormOptions(data) {
      //common
      this.options.available_languages = data.options.available_languages;
      this.options.location_availability = data.options.location_availability;
      this.options.categories = data.options.categories;
      this.options.categories_client = data.options.categories_client;
      this.options.sub_types = data.options.sub_types;
      this.options.subscription_periods = data.options.subscription_periods;
      this.options.delivery_types = data.options.delivery_types;
      this.options.taxes = data.options.taxes;
      this.options.form_builder_versions = data.options.form_builder_versions;
      this.options.partners = data.options.partners;
      this.options.counties = data.options.counties;
      this.options.budget_consumption_types = data.options.budget_consumption_types;
      this.options.available_systems = data.options.available_systems;
      let ppp3Array =[];

      (data.options.ppp3_benefits).forEach((value) => {
        if (value.parent_id != null ) {
          return false;
        }
        ppp3Array.push(value);
      });
      this.options.child_sub_types = ppp3Array;
      let objToPush, langCode, index;
      let formData = {...this.formData};
      //multi-lang
      for (index in data.options.available_languages) {
        langCode = data.options.available_languages[index];
        if (this.formAction === 'create') {
          objToPush = {
            lang_code: langCode,
            value: ''
          };
          formData.description.push({...objToPush});
          formData.rationale.push({...objToPush});
        } else {
          formData.description.push({
            lang_code: langCode,
            value: data.item.description[langCode]
          });
          formData.rationale.push({
            lang_code: langCode,
            value: data.item.rationale[langCode]
          });
        }
      }

      //specific
      if (this.formAction === 'update') {
        formData.id = data.item.id;
        formData.name = data.item.name;
        formData.invoice_name = data.item.invoice_name;
        formData.parent_id = data.item.parent_id;
        formData.form_builder_version = data.item.form_builder_version;
        formData.crm_code = data.item.crm_code;
        formData.product_link = data.item.product_link;
        formData.status = data.item.status.toLowerCase();
        formData.color = data.item.color;
        formData.is_legislated = data.item.is_legislated;
        formData.fiscal_treatment = data.item.fiscal_treatment;
        formData.image_url = data.item.image_url;
        formData.icon_url = data.item.icon_url;
        formData.global_variable_codes = data.item.global_variable_codes;
        formData.start_use_date = data.item.start_use_date; // up_gift has these in at child level
        formData.end_use_day_month = data.item.end_use_day_month; // up_gift has these in at child level
        formData.partner_id = data.item.partner_id;
        formData.category_ids = data.item.category_ids;
        formData.promoted_as_new = data.item.promoted_as_new;
        formData.county_availability = data.item.county_availability;
        formData.country_availability = data.item.country_id;
        formData.brand_availability = data.item.brand_id;
        formData.location_availability = data.item.location_availability;
        formData.budget_consumption_types = data.item.budget_consumption_types;
        formData.can_edit_budget_consumption_type = data.item.can_edit_budget_consumption_type;

        let pp3_benefits = [];
        if (this.formData.parent_id === null) {
          (data.options.ppp3_benefits).forEach((value, index) => {
            if (data.item.id == value.parent_id) {
              this.isParent = true;
            }
          });
        }
        if (this.isParent) {
          this.options.child_sub_types = null;
        }else {
          //daca are parent_id nu poate aparea in select
          (data.options.ppp3_benefits).forEach((value) => {
            if (data.item.id == value.id || value.parent_id != null ) {
              return false;
            }
            pp3_benefits.push(value);
          });
          this.options.child_sub_types = pp3_benefits;
        }

        formData.vat = data.item.vat;
        this.assigned_categories = data.item.categories;
        if (data.item.county_availability == 1) {
          this.selected_counties = data.options.counties.filter(function (item) {
            return data.item.county_ids.includes(item.id);
          });
        }

        data.item.thresholds.forEach(function (item) {
          formData.thresholds.push({name:item.code, value: true})
        })

        this.selected_systems = data.item.os_availability;

        formData.data = data.item.data;//benefit properties

        formData.future_values = data.item.future_values;
        if (formData.is_legislated) {
          formData.children = data.item.children;//benefit properties
        } else {

          if (!data.item.dynamic_form) {
            formData.sub_type = data.item.sub_type;
            this.subType = data.item.sub_type;
          } else {
            formData.sub_type = data.item.dynamic_form;
            let fields = {...data.item.dynamic_form.fields};

            if (typeof fields.dynamic_fields !== "undefined") {
              fields.dynamic_fields = (fields.dynamic_fields !== null) ? fields.dynamic_fields : '';//todo: remove when form builder is implemented
              fields.dynamic_fields = (fields.dynamic_fields.length > 0) ? JSON.stringify(fields.dynamic_fields) : '';
            }
            formData.dynamic_fields = fields;
            this.subType = data.item.sub_type;// check watcher on update form
          }
        }
      }
      this.formData = formData;
    },
    _prepareForm() {
      this.errorsBag = {};
      let formData = new FormData();
      let status = {},
        description = {},
        rationale = {},
        index = 0;

      formData.append('image', this.formData.image);
      formData.append('icon', this.formData.icon);
      formData.append('name', this.formData.name);
      formData.append('invoice_name', this.formData.invoice_name);
      formData.append('form_builder_version', this.formData.form_builder_version);
      formData.append('color', this.formData.color);
      formData.append('product_link', this.formData.product_link);
      formData.append('crm_code', this.formData.crm_code);
      formData.append('partner_id', this.formData.partner_id);

      for (index in this.formData.description) {
        description[this.formData.description[index].lang_code] = this.formData.description[index].value;
      }
      formData.append('description', JSON.stringify(description));

      index = 0;
      for (index in this.formData.rationale) {
        rationale[this.formData.rationale[index].lang_code] = this.formData.rationale[index].value;
      }
      formData.append('rationale', JSON.stringify(rationale));

      formData.append('status', (this.modal.options.saveAs === 'publish') ? 'active' : this.formData.status);
      formData.append('start_use_date', (this.formData.start_use_date !== null) ? moment(this.formData.start_use_date).format('YYYY-MM-DD') : null);
      formData.append('is_legislated', this.formData.is_legislated);
      formData.append('county_availability', this.formData.county_availability);
      if (this.formData.country_availability) {
        formData.append('country_availability', this.formData.country_availability);
      }
      if (this.formData.brand_availability) {
        formData.append('brand_availability', this.formData.brand_availability);
      }
      formData.append('location_availability', this.formData.location_availability);
      formData.append('county_ids', JSON.stringify(this.formData.county_ids));
      formData.append('os_availability', JSON.stringify(this.formData.systems));
      formData.append('fiscal_treatment', JSON.stringify(this.formData.fiscal_treatment));

      formData.append('end_use_day_month', JSON.stringify(this.formData.end_use_day_month));
      formData.append('category_ids', JSON.stringify(this.formData.category_ids));
      formData.append('future_values', JSON.stringify(this.formData.future_values));
      formData.append('children', JSON.stringify(this.formData.children));
      formData.append('thresholds', JSON.stringify(this.formData.thresholds));
      formData.append('global_variable_codes', JSON.stringify(this.formData.global_variable_codes));
      formData.append('budget_consumption_types', JSON.stringify(this.formData.budget_consumption_types));
      formData.append('vat', JSON.stringify(this.formData.vat));

      //dynamic data
      formData.append('sub_type', this.subType);

      if (!this.formData.is_legislated) {
        let dynamicForm = {...this.dynamicForm};
        dynamicForm.dynamic_fields = this._getJsonEncodedDynamicFields(dynamicForm.dynamic_fields);//todo: remove when form builder is implemented
        formData.append('dynamic_fields', JSON.stringify(dynamicForm));
      }

      return formData;
    },
    _getJsonEncodedDynamicFields(fields) {
      if (fields === null || Object.keys(fields || {}).length === 0) {
        return null;
      }
      //todo: remove when form builder is implemented
      try {
        return JSON.parse(fields.replace(/\B\s+|\s+\B/g, ''));
      } catch (e) {
        this.errorsBag['dynamic_fields'] = 'invalid_json';
        return null;
      }
    },
    _createItem() {
      let formData = this._prepareForm();
      if (this.checkNotEmptyObj(this.errorsBag)) {
        this.loading = false;
        this.scrollToTop();
        return;
      }
      this.httpService.create(formData)
        .then((response) => {
          this.modal.action = 'end';
          this.modal.formSuccess = true;
          this.loading = false;
          this.modal.open = true;
        })
        .catch((error) => {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else if (error.response.status === 400) {
            this.modal.action = 'end';
            this.errorsBag = error.response.data.error_fields;
            this.modal.formSuccess = false;
            this.modal.message = this.$t('flexben.general.validation_errors');
            this.loading = false;
            this.modal.open = true;
            this.scrollToTop();
          } else {
            this.loading = false;
          }
        });
    },
    _updateItem() {
      let formData = this._prepareForm();
      if (this.checkNotEmptyObj(this.errorsBag)) {
        this.loading = false;
        this.scrollToTop();
        return;
      }
      this.httpService.update(this.itemId, formData)
        .then((response) => {
          this.modal.action = 'end';
          this.modal.formSuccess = true;
          this.loading = false;
          this.modal.open = true;
        })
        .catch((error) => {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else if (error.response.status === 400) {
            this.modal.action = 'end';
            this.errorsBag = error.response.data.error_fields;
            this.modal.formSuccess = false;
            this.modal.message = this.$t('flexben.general.validation_errors');
            this.loading = false;
            this.modal.open = true;
            this.scrollToTop();
          } else {
            this.loading = false;
          }
        });
    },
    _deleteItem() {
      this.httpService.changeStatus(this.itemId, {status: 'deleted'})
        .then((response) => {
          this.modal.action = 'end';
          this.modal.formSuccess = true;
          this.loading = false;
          this.modal.open = true;
        })
        .catch((error) => {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else if (error.response.status === 400) {
            this.modal.action = 'end';
            this.errorsBag = error.response.data.error_fields;
            this.modal.formSuccess = false;
            this.loading = false;
            this.modal.open = true;
          } else {
            this.loading = false
          }
        });
    },
    _changeStatus() {
      this.httpService.changeStatus(this.itemId, {status: this.modal.options.statusChange})
        .then((response) => {
          this.modal.action = 'end';
          this.modal.formSuccess = true;
          this.formData.status = this.modal.options.statusChange;
          this.loading = false;
          this.modal.open = true;
        })
        .catch((error) => {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
          } else {
            this.loading = false
          }
        });
    },
    _buildSubscriptionFrom() {
      if (this.formAction === 'create') {
        this.dynamicForm = {
          added_periods: [],
          added_period_fields: [],
          dynamic_fields: '',
          thresholds: []
        };
      } else { //update
        this.dynamicForm = {
          added_periods: this.formData.dynamic_fields.added_periods,
          added_period_fields: this.formData.dynamic_fields.added_period_fields,
          dynamic_fields: this._getDynamicFieldString(),
          thresholds: this.formData.thresholds
        };
      }
    },
    _buildVoucherFrom() {
      if (this.formAction === 'create') {
        this.dynamicForm = {
          delivery_type: null,
          billing_value: 0,
          dynamic_fields: '',
          thresholds: []
        };
      } else { //update
        this.dynamicForm = {
          delivery_type: this.formData.dynamic_fields.delivery_type,
          billing_value: this.formData.dynamic_fields.billing_value,
          thresholds: this.formData.thresholds,
          dynamic_fields: this._getDynamicFieldString()
        };
      }
    },
    _buildPpp3Form() {
      if (this.formAction === 'create') {
        this.dynamicForm = {
          min_order_value: 100,
          dynamic_fields: ''
        };
      } else { //update
        this.dynamicForm = {
          min_order_value: this.formData.dynamic_fields.min_order_value,
          child_sub_type: this.formData.parent_id,
          is_parent: this.isParent,
          dynamic_fields: this._getDynamicFieldString()
        };
      }
    },
    _buildMedicalInsuranceForm() {
      if (this.formAction === 'create') {
        this.dynamicForm = {
          min_order_value: 100,
          dynamic_fields: ''
        };
      } else { //update
        this.dynamicForm = {
          min_order_value: this.formData.dynamic_fields.min_order_value,
          dynamic_fields: this._getDynamicFieldString()
        };
      }
    },
    _buildSubscriptionWorldClassForm() {
      this.dynamicForm = {
        last_day_to_order_for_next_month: this.formData.dynamic_fields.last_day_to_order_for_next_month,
        locations: this.formData.dynamic_fields.locations,
        dynamic_fields: this._getDynamicFieldString(),
        thresholds: this.formData.thresholds
      };
    },
    _buildPointsPagoForm() {
      this.dynamicForm = {
        dynamic_fields: ''
      };
    },
    _getDynamicFieldString() {
      //todo: remove json parse when form builder is implemented at the moment will be a manually imputed json by operator
      return (this.checkNotEmptyObj(this.formData.dynamic_fields) && this.formData.dynamic_fields.dynamic_fields.length > 0) ? this.formData.dynamic_fields.dynamic_fields : '';
    },
    addBenefitThreshold(name, value) {
      if (this.formData.thresholds) {
        let item = this.formData.thresholds.find(function (item) {return item.name === name});
        if (item) {
          this.formData.thresholds.find(function (item) {return item.name === name}).value = value;
          return;
        }
      }

      this.formData.thresholds.push({name:name, value: value})
    }
  }
}
