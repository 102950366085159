var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tab',{key:_vm.tabIndex,class:'container-bg-white pt-2',attrs:{"title":_vm.$t('flexben.clients.benefits.pp3')}},[_c('div',[_c('div',{staticClass:"row pt-2 px-3"},[_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"text-20 text-bold"},[_vm._v(_vm._s(_vm.$t('flexben.clients.benefits.pp3')))])])]),_c('div',{staticClass:"row mt-4 mx-1"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"container-fluid px-0 m-0"},[_c('div',{staticClass:"table-responsive hover-table"},[_c('table',{staticClass:"table no-min-width"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("flexben.benefit_groups.detail.ben_name")))]),_c('th',{staticClass:"d-none d-md-table-cell"},[_vm._v(_vm._s(_vm.$t("flexben.benefit_groups.detail.contribution.client")))]),(_vm.showPersonalContributions)?_c('th',{staticClass:"d-none d-md-table-cell"},[_vm._v(_vm._s(_vm.$t("flexben.benefit_groups.detail.contribution.personal")))]):_vm._e(),(_vm.showPersonalContributions)?_c('th',{staticClass:"d-none d-sm-table-cell"},[_vm._v(_vm._s(_vm.$t("flexben.clients.partial.ppp3_enable_benefit_for_client")))]):_vm._e(),(_vm.isAdmin)?_c('th',{staticClass:"w-50-p"}):_vm._e()])]),(_vm.benefits.length > 0)?_c('tbody',_vm._l((_vm.benefits),function(benefit,index){return _c('tr',{key:index,staticClass:"trhover"},[_c('td',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"flx-table-image mr-2",attrs:{"src":benefit.image_url,"loading":"lazy"}}),_c('span',{staticClass:"color-bullet mr-2",style:({backgroundColor: benefit.color})}),_vm._v(" "+_vm._s(benefit.name)+" ")]),_c('td',{staticClass:"d-none d-md-table-cell links-container"},[_c('div',{staticClass:"form-group mb-1 mt-2"},[(benefit.benefit_configurations &&
                               benefit.benefit_configurations[0] &&
                               benefit.benefit_configurations[0].configuration &&
                               'contribution' in benefit.benefit_configurations[0].configuration)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(benefit.benefit_configurations[0].configuration['contribution']['client']),expression:"benefit.benefit_configurations[0].configuration['contribution']['client']"}],staticClass:"form-control",attrs:{"id":"client"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(benefit.benefit_configurations[0].configuration['contribution'], 'client', $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l(((_vm.formOptions.ppp3_contributions)),function(type){return _c('option',{key:type,domProps:{"value":type}},[_vm._v(_vm._s(type))])}),0)]):_vm._e()]),_c('br')]),(_vm.showPersonalContributions)?_c('td',{staticClass:"d-none d-md-table-cell links-container"},[(benefit.benefit_configurations &&
                      benefit.benefit_configurations[0] &&
                      benefit.benefit_configurations[0].configuration &&
                      'contribution' in benefit.benefit_configurations[0].configuration)?_c('div',{staticClass:"form-group mb-1 mt-2"},[_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(benefit.benefit_configurations[0].configuration['contribution']['personal']),expression:"benefit.benefit_configurations[0].configuration['contribution']['personal']"}],staticClass:"form-control",attrs:{"id":"personal"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(benefit.benefit_configurations[0].configuration['contribution'], 'personal', $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l(((_vm.formOptions.ppp3_contributions)),function(type){return _c('option',{key:type,domProps:{"value":type}},[_vm._v(_vm._s(type))])}),0)])]):_vm._e()]):_vm._e(),(_vm.showPersonalContributions)?_c('td',{staticClass:"d-none d-sm-table-cell links-container text-center",staticStyle:{"width":"11%"}},[_c('div',{staticClass:"form-group mb-1 mt-2"},[(benefit.benefit_configurations &&
                               benefit.benefit_configurations[0] &&
                               benefit.benefit_configurations[0].configuration &&
                               'personal_contribution_active' in benefit.benefit_configurations[0].configuration)?_c('div',[_c('b-form-checkbox',{staticClass:"d-inline-block",attrs:{"switch":""},model:{value:(benefit.benefit_configurations[0].configuration['personal_contribution_active']),callback:function ($$v) {_vm.$set(benefit.benefit_configurations[0].configuration, 'personal_contribution_active', $$v)},expression:"benefit.benefit_configurations[0].configuration['personal_contribution_active']"}})],1):_vm._e()]),_c('br')]):_vm._e()])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center trhover gri-cell",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("flexben.benefit_groups.no_benefits_available",{type:_vm.$t('flexben.benefit_groups.benefit_type_flexible')}))+" ")])])])])])])]),_c('div',{staticClass:"mb-4 col-12"},[_c('button',{staticClass:"btn save-btn px-5 float-right",on:{"click":function($event){return _vm.submitSettings()}}},[_vm._v(_vm._s(_vm.$t("flexben.general.btn.save"))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }