export default [
  {
    Companies: [
      {
        fileName: "login/companii/up-dejun-up-online.png",
        link: "https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fupromania.ro%2Fcompanii%2Fcard-de-masa%2F&data=05%7C01%7Cvpopa%40upromania.ro%7C8425de7d93d24c630b2408dac97f6eee%7C475fd1d1212f472f9c02635526f47568%7C0%7C0%7C638043846413138670%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=FAJbDBAoNBuAJKv%2BCUkfxNchxv0oS6opGlG%2FjsKcbJY%3D&reserved=0",
        category: "Banner",
        action: "Click",
        label: "Up_dejun_campanie",
      },
      {
        fileName: "login/companii/up-cultura-up-onlline.png",
        link:
        "https://upromania.ro/companii/card-cultural/",
        category: "Banner",
        action: "Click",
        label: "Companii_cultura_VN220",
      },
      {
        fileName: "login/companii/up-vacanta-up-online.png",
        link: "https://catalogulcuvacante.ro/b2c/#/up/catalogs",
        category: "Banner",
        action: "Click",
        label: "Companii_ConfigVacante",
      },
      {
        fileName: "login/companii/banner_recomanda.png",
        link:
          "https://upromania.ro/recomanda/?utm_source=uponline&utm_medium=banner&utm_campaign=Recomanda",
        category: "Banner",
        action: "Click",
        label: "Companii_Recomanda",
      },
    ],
    Beneficiaries: [
      {
        fileName: "login/utilizatori/banner_mobilplus.png",
        link:
          "https://upromania.page.link/ump",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_mobilplus",
      },
      {
        fileName: "login/utilizatori/banner_blenche.png",
        link:
          "https://www.youtube.com/watch?v=Yeo8EFuJ0TM",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_blenche",
      },
      {
        fileName: "login/utilizatori/banner_sezamo.png",
        link:
          "",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_sezamo",
      }, 
      {
        fileName: "login/companii/banner_recomanda.png",
        link: "https://upromania.ro/recomanda/",
        category: "Banner",
        action: "Click",
        label: "UtilizatoriRecomandaCastiga",
      },
    ],
  },
];
